import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Gamecard } from '../model/gamecard';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GameCardService {
  constructor(private http: HttpClient) { }


  addGameCard(card: Gamecard): Observable<any> {
    console.dir(card);
    return this.http.post(environment.serverBaseUrl + "card", card);
  }

  getGameCard(id: string): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    let params = new HttpParams();
    params.append("id", id)


    return this.http.get<Gamecard>(environment.serverBaseUrl + "card", 
    { headers: headers, params: params });
  }

  getRandomGameCard(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.get<Gamecard>(environment.serverBaseUrl + "card", { headers: headers });
  }

  getAllCards(): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http.get<Gamecard>(environment.serverBaseUrl + "card", { headers: headers });
  }


}

