import { Component, OnInit } from '@angular/core';
import { Gamecard } from 'src/app/model/gamecard';
import { GameCardService } from '../../services/game-card.service';

@Component({
  selector: 'app-list-all',
  templateUrl: './list-all.component.html',
  styleUrls: ['./list-all.component.scss']
})
export class ListAllComponent implements OnInit {

  cards: Array<Gamecard>;
  constructor(private cardService: GameCardService) { }

  ngOnInit() {
    this.cardService.getAllCards().subscribe(cards => this.cards = cards);
  }

}
