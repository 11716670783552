import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateRoomComponent } from './room/create-room/create-room.component';
import { WaitingRoomComponent } from './room/waiting-room/waiting-room.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button'

import {MatTableModule} from '@angular/material/table'; 

import {MatTooltipModule} from '@angular/material/tooltip'; 

import {MatInputModule} from '@angular/material/input'; 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlayingFieldComponent } from './game/playing-field/playing-field.component';
import { SolutionFieldComponent } from './game/solution-field/solution-field.component';
import { PointsTableComponent } from './game/points-table/points-table.component';
import { InputZoneComponent } from './game/input-zone/input-zone.component';
import { GameContainerComponent } from './game/game-container/game-container.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AddGameCardComponent } from './add-game-card/add-game-card.component';
import { HttpClientModule } from '@angular/common/http';
import { ListAllComponent } from './card/list-all/list-all.component';
import { PlayerListComponent } from './game/player-list/player-list.component';
import { SortByPipe } from './room/sort-by.pipe';

@NgModule({
  declarations: [
    AppComponent,
    CreateRoomComponent,
    WaitingRoomComponent,
    PlayingFieldComponent,
    SolutionFieldComponent,
    PointsTableComponent,
    InputZoneComponent,
    GameContainerComponent,
    HomeComponent,
    NotFoundComponent,
    AddGameCardComponent,
    ListAllComponent,
    PlayerListComponent,
    SortByPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    HttpClientModule,
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
