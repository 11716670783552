import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Gamecard } from '../../model/gamecard';

@Component({
  selector: 'app-playing-field',
  templateUrl: './playing-field.component.html',
  styleUrls: ['./playing-field.component.scss']
})
export class PlayingFieldComponent implements OnInit {
  @Input() gameCard: Gamecard;
  displayedColumns: string[] = ['number', 'a', 'b', 'c', 'd'];
  constructor() { }

  ngOnInit() {
  }

  transformWordsToTable(words){
    //console.log(typeof words);
    if(typeof words == "string"){
      words = words.split(",");
    }
    let table = []
    for(let i = 0; i < 4; i++){
      let row = {
        'number': i+1, 
      'a': words[i*4], 
      'b': words[i*4+1], 
      'c': words[i*4+2], 
      'd': words[i*4+3] }


      table.push(row);
    }
    return table;
  }
}
