import { FormControl } from '@angular/forms';
export const wordsLengthValidator = (control: FormControl) => {
  if (control.value == null
    || control.value.split(',').length < 16
    || (
        control.value.split(',').length == 16
      &&
        control.value.split(',')[15].length < 1)) {
    return { 'tooShort': true };
  }
  if (control.value.split(',').length > 16) {
    return { 'tooLong': true };
  }
  return null;

}