import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Player } from 'src/app/model/player';
import { State } from '../../state/localstate';

@Component({
  selector: 'app-solution-field',
  templateUrl: './solution-field.component.html',
  styleUrls: ['./solution-field.component.scss']
})
export class SolutionFieldComponent implements OnInit {

  @Input() solutions: Array<Player>;
  individualSolution: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.solutions = changes.solutions.currentValue;
    this.determineIndSol();
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values
    
}

  determineIndSol(){
    this.individualSolution = this.solutions.filter(player => player.name == State.getInstance().getPlayerName())[0].role;
  }

}
