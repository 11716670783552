import { Component, OnInit } from '@angular/core';
import { GameCardService } from '../services/game-card.service';
import { Languages } from '../model/languages';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { wordsLengthValidator } from './words-length-validator';

@Component({
  selector: 'app-add-game-card',
  templateUrl: './add-game-card.component.html',
  styleUrls: ['./add-game-card.component.scss']
})
export class AddGameCardComponent implements OnInit {
  languages = Languages;
  errorMessage: string;
  addCardForm = new FormGroup({
    title: new FormControl('', Validators.required),
    words: new FormControl('', [Validators.required, wordsLengthValidator]),
    language: new FormControl('', Validators.required),
  })
  constructor(private cardService: GameCardService) { }

  ngOnInit() {
  }

  addCard() {
    this.errorMessage = '';
    this.cardService
      .addGameCard(this.addCardForm.value)
      .subscribe(
        {
          next: data => {
            this.addCardForm.reset();
          },
          error: error => {
            this.errorMessage = error.message;
            console.error('There was an error!', error);
          }
        });
  }

}
