import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateRoomComponent } from './room/create-room/create-room.component';
import { WaitingRoomComponent } from './room/waiting-room/waiting-room.component';
import { GameContainerComponent } from './game/game-container/game-container.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AddGameCardComponent } from './add-game-card/add-game-card.component';
import { ListAllComponent } from './card/list-all/list-all.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'room/new', component: CreateRoomComponent },
  { path: 'room/:roomcode', component: WaitingRoomComponent },
  { path: 'play/:roomcode', component: GameContainerComponent },
  { path: 'add/gamecard', component: AddGameCardComponent },
  { path: 'show/card/all', component: ListAllComponent },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
