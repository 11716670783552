import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoomService } from '../room.service';
import { Room } from '../../model/room';
import { State } from '../../state/localstate';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss']
})
export class WaitingRoomComponent implements OnInit {

  room: Room;
  localPlayerId: string;

  name: string = '';

  constructor(private route: ActivatedRoute,
    private roomservice: RoomService,
    private router: Router) {
    this.room = null;
  }

  ngOnInit() {
    this.localPlayerId = State.getInstance().getPlayerId();
    this.route.params.subscribe(params => {
      State.getInstance().setRoomcode(params['roomcode']);      //Get roomcode
      this.roomservice.loadRoom(State.getInstance().getRoomcode())       //load room
        .subscribe(room => {
          if (room.players.filter(player => player.id == this.localPlayerId).length == 0) { //join only if not already in room
            this.roomservice.joinRoom(State.getInstance().getRoomcode()).subscribe(room => {
              console.log(`${this.localPlayerId} joined room: ${room.roomcode}.`);
              this.room = room;
            });
          }
          this.room = room;
        });

      this.roomservice.subscribeToSSE(State.getInstance().getRoomcode())
        .subscribe(
          data => this.loadRoom(data)
        );
    }
    );
  }

  loadRoom(data): void {

    console.log(`New Update: ${data}.`);
    this.roomservice.loadRoom(State.getInstance().getRoomcode()).subscribe(room => {
      console.log("Loaded room: " + room.roomcode);

      if (room.card != null) {
        this.router.navigate(['/play', State.getInstance().getRoomcode()]);
      }
      this.room = room;
    });

  }

  leaveGame() {
    this.roomservice.leaveRoom(this.room.roomcode).subscribe(data => {
      this.router.navigate(['/']);
    });
  }

  startGame() {
    this.roomservice
      .nextRound(this.room.roomcode)
      .subscribe(roomcode => {
        this.router.navigate(['/play', roomcode]);
      });
  }

  saveName() {
    State.getInstance().setPlayerName(this.name);
    console.log(`Changing name of player ${this.localPlayerId} to ${State.getInstance().getPlayerName()}`)
    this.roomservice
      .renamePlayer(this.room.roomcode)
      .subscribe(() => {
        this.name = "";
      });
  }

}
