import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Player } from 'src/app/model/player';
import { State } from 'src/app/state/localstate';
import { VotingService } from '../../services/voting.service';

@Component({
  selector: 'app-player-list',
  templateUrl: './player-list.component.html',
  styleUrls: ['./player-list.component.scss']
})
export class PlayerListComponent implements OnInit {


  @Input() players: Array<Player>;
  @Input() localPlayerId: string;

  constructor(private votingService: VotingService) { }

  ngOnInit() {
  }

  vote(votingForPlayerId: string): void {
    console.log(`Voting for ${votingForPlayerId}`);
    this.votingService.vote(State.getInstance().getRoomcode(), votingForPlayerId).subscribe(
      data => console.log(data)
    )
  }

}
