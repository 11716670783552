import { DEFAULT_NAMES, DEFAULT_ADJECTIVES } from '../consts/default-names.const';

export class State {

    private static STATE: State;
    private static KEY = {
        ID: "playerId",
        NAME: "playerName",
        ROOMCODE: "roomcode"
    }
    static getInstance() {
        if (State.STATE == null || State.STATE == undefined) {
            State.STATE = new State();

        }
        return State.STATE;
    }


    private constructor() {

    }

    getPlayerName(): string {
        let name = localStorage.getItem(State.KEY.NAME);
        if(name == null || name.trim().length == 0){
            name = this.getRandomDefaultName();
            this.setPlayerName(name);
        }
        return name;
    }

    getPlayerId(): string {
        let id = localStorage.getItem(State.KEY.ID);
        if(id == null || id.trim().length == 0){
            id = this.generateId();
            this.setPlayerId(id);
        }
        return id;
    }

    getRoomcode(): string {
        return localStorage.getItem(State.KEY.ROOMCODE);
    }

    setPlayerName(name: string): string {
        localStorage.setItem(State.KEY.NAME, name);
        return name;
    }

    setRoomcode(roomCode: string): string {
        localStorage.setItem(State.KEY.ROOMCODE, roomCode);
        return roomCode;
    }

    private setPlayerId(playerId: string): string {
        localStorage.setItem(State.KEY.ID, playerId);
        return playerId;
    }

    private getRandomDefaultName() : string{
        return DEFAULT_ADJECTIVES[Math.floor(Math.random() * DEFAULT_ADJECTIVES.length)] + " " + DEFAULT_NAMES[Math.floor(Math.random() * DEFAULT_NAMES.length)]
    }

    private generateId(): string{
        return (Math.random() * 0xFFFFFF << 0).toString(32).padStart(4, '0');
    }
}