import { getTreeMissingMatchingNodeDefError } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Room } from '../model/room';
import { HttpClient } from '@angular/common/http';
import { State } from '../state/localstate';
import { environment } from 'src/environments/environment';
import { Gamecard } from '../model/gamecard';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(private http: HttpClient) { }


  loadAllJoinableRooms(): Observable<Array<Room>> {
    return this.http.get<Array<Room>>(environment.serverBaseUrl + "room/");
  }

  loadRoom(roomcode: string): Observable<Room> {
    return this.http.get<Room>(environment.serverBaseUrl + "room/" + roomcode);
  }

  createRoom(room: Room): Observable<string> {
    console.log(room);
    console.log(environment.serverBaseUrl + "room")
    return this.http.post(environment.serverBaseUrl + "room", room, { responseType: 'text' });
  }

  joinRoom(roomcode: string): Observable<Room> {

    return this.http.patch<Room>(environment.serverBaseUrl + "room/" + roomcode + "/join",
      {
        "id": State.getInstance().getPlayerId(),
        "name": State.getInstance().getPlayerName(),
        "role": ""
      }
    );
  }

  renamePlayer(roomcode: String): Observable<void>{
    return this.http.patch<void>(environment.serverBaseUrl + "room/" + roomcode + "/renamePlayer",
    {

      "id": State.getInstance().getPlayerId(),
      "name": State.getInstance().getPlayerName(),
      "role": ""
    }
  );
  }

  leaveRoom(roomcode: string): Observable<Room> {
    console.log("Leaving room " + roomcode);
    console.log(State.getInstance().getPlayerName());
    return this.http.patch<Room>(environment.serverBaseUrl + "room/" + roomcode + "/leave",
      {

        "id": State.getInstance().getPlayerId(),
        "name": State.getInstance().getPlayerName(),
        "role": ""
      }
    );
  }

  nextRound(roomcode: string, cardId?: string): Observable<Room> {
    return this.http.post<Room>(environment.serverBaseUrl + "room/" + roomcode + "/next" + ( cardId != null ? `/${cardId}` : ""), {});
  }


  subscribeToSSE(roomcode: string): Observable<any> {
    console.log("subscribing to " + roomcode);
    return this.observeMessages(environment.serverBaseUrl + "room/" + roomcode + "/subscribe");
  }

  observeMessages(sseUrl: string): Observable<any> {
    return new Observable<any>(obs => {
      const es = new EventSource(sseUrl);
      es.addEventListener('message', (evt) => {
        //console.log(evt.data);
        obs.next(evt.data);
      });
      return () => es.close();
    });
  }

}
