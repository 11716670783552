import { Component, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { GameCardService } from 'src/app/services/game-card.service';

@Component({
  selector: 'app-input-zone',
  templateUrl: './input-zone.component.html',
  styleUrls: ['./input-zone.component.scss']
})
export class InputZoneComponent implements OnInit {

  @Output() next = new EventEmitter();
  @Output() leave = new EventEmitter();

  cards: Array<any>;
  selectedNextCardId: String;

  constructor(private gcs: GameCardService) { }

  ngOnInit() {
    this.gcs.getAllCards()
      .subscribe(cards => this.cards = cards);
  }

  nextRound($event){
    $event.nextcardId = this.selectedNextCardId;
    this.next.emit($event);
  }

  leaveGame($event){
    this.leave.emit($event);
  }
}
