import { Component, OnInit } from '@angular/core';
import { Room } from '../model/room';
import { RoomService } from '../room/room.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  joinableRooms: Array<Room>;

  constructor(private service: RoomService) { 
    this.joinableRooms = new Array<Room>();
  }

  ngOnInit() {
    this.service.loadAllJoinableRooms().subscribe(rooms => this.joinableRooms = rooms);
  }

}
