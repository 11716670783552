import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { Room } from 'src/app/model/room';
import { RoomService } from '../../room/room.service';
import { State } from '../../state/localstate';

@Component({
  selector: 'app-game-container',
  templateUrl: './game-container.component.html',
  styleUrls: ['./game-container.component.scss']
})
export class GameContainerComponent implements OnInit {

  room: Room;
  localPlayerId = State.getInstance().getPlayerId();


  constructor(private service: RoomService, private router: Router) {

    //console.log("loading room:" + State.getInstance().getRoomcode());

    this.service.subscribeToSSE(State.getInstance().getRoomcode()).subscribe(
      data => {
        console.log(data);
        this.service.loadRoom(State.getInstance().getRoomcode())
          .subscribe(room => {
            this.room = room;
          });
      });

    this.service.loadRoom(State.getInstance().getRoomcode())
      .subscribe(room => {
        this.room = room;
      });

  }

  ngOnInit() {
  }



  loadNextGame($event) {
    console.log($event.nextcardId);
    this.service
      .nextRound(State.getInstance().getRoomcode(), $event.nextcardId)
      .subscribe(room => console.log("started next round"));

  }

  leaveGame($event) {

    this.service
      .leaveRoom(State.getInstance().getRoomcode())
      .subscribe(room => {
        State.getInstance().setRoomcode(null);
        this.router.navigate(['/']);
      });

  }

  private arraysEqual(a: Array<any>, b: Array<any>): boolean {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }
}
