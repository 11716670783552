import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RoomService } from '../room.service';
import { Router } from '@angular/router';
import { Languages } from 'src/app/model/languages';
import { State } from '../../state/localstate';

@Component({
  selector: 'app-create-room',
  templateUrl: './create-room.component.html',
  styleUrls: ['./create-room.component.scss']
})
export class CreateRoomComponent implements OnInit {

  languages = Languages;
  errorMessage: string;

  createRoomForm  = new FormGroup({
    maxPlayers: new FormControl('', [Validators.required, Validators.min(2)]),
    numChameleons: new FormControl('', [Validators.required, Validators.min(1)]),
    language: new FormControl('', Validators.required),
    publicGame: new FormControl('')
  })
  constructor(private roomService: RoomService, private router: Router) { }

  ngOnInit() {
  }

  createRoom() {
    this.roomService.createRoom({
      "roomcode": "",
      "players": [],
      settings: this.createRoomForm.value,
      card: null
    }).subscribe( {next: roomCode => {
      this.router.navigate(['/room', roomCode]);
    }, 
    error: error => {
      this.errorMessage = error.message;
      console.error('There was an error!', error);
    }
    
  });

  }

}
