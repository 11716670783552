import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RoomService } from './room/room.service';
import { State } from './state/localstate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  roomcode = new FormControl("", [Validators.required,
  Validators.minLength(5), Validators.maxLength(5)]);
  
  constructor(private service: RoomService) {
    //service.subscribeToSSE("46d13").subscribe(data => console.log(data));
  }

  join() {

  }

  create() {

  }

  ngOnDestroy(){
    this.service.leaveRoom(State.getInstance().getRoomcode()).subscribe(data => console.log("left room!"));
  }
}
